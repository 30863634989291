@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {


html {
  font-family: 'Avenir', sans-serif;
}

html {
    scroll-behavior: smooth;
}

/* Chrome, Safari, Edge, Opera */

input.zipcode[type=number]::-webkit-inner-spin-button, input.zipcode[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none; 
  appearance: none; 
}

.tooltip-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.tooltip {
  display: flex;
  align-items: center;
  position: relative;
}
.tooltip .tooltip-trigger {
  display: flex;
  align-items: center;
  background-color: #000;
  color: #000;
  border-radius: 0.375rem;
  cursor: pointer;
}
.tooltip:hover .tooltip-drop {
  opacity: 1;
}
.tooltip:hover .tooltip-drop.tooltip-top, .tooltip:hover .tooltip-drop.tooltip-bottom {
  transform: translate(-50%, 0);
}
.tooltip:hover .tooltip-drop.tooltip-left, .tooltip:hover .tooltip-drop.tooltip-right {
  transform: translate(0, -50%);
}

.tooltip .tooltip-drop::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-top-color: #000;
}
.tooltip .tooltip-drop.tooltip-left, .tooltip .tooltip-drop.tooltip-right {
  top: 50%;
}
.tooltip .tooltip-drop.tooltip-left:before, .tooltip .tooltip-drop.tooltip-right:before {
  top: 50%;
}
.tooltip .tooltip-drop.tooltip-left {
  right: calc(100% + 0.625rem);
  transform: translate(-0.25rem, -50%);
}
.tooltip .tooltip-drop.tooltip-left:before {
  left: 100%;
  transform: translate(0, -50%) rotate(-90deg);
}
.tooltip .tooltip-drop.tooltip-right {
  left: calc(100% + 0.625rem);
  transform: translate(0.25rem, -50%);
}
.tooltip .tooltip-drop.tooltip-right:before {
  right: 100%;
  transform: translate(0, -50%) rotate(90deg);
}
.tooltip .tooltip-drop.tooltip-top, .tooltip .tooltip-drop.tooltip-bottom {
  left: 50%;
  transform: translate(-50%, 0.625rem);
}
.tooltip .tooltip-drop.tooltip-top:before, .tooltip .tooltip-drop.tooltip-bottom:before {
  left: 50%;
}
.tooltip .tooltip-drop.tooltip-top {
  bottom: calc(100% + 0.625rem);
}
.tooltip .tooltip-drop.tooltip-top:before {
  top: 100%;
  transform: translate(-50%, 0);
}
.tooltip .tooltip-drop.tooltip-bottom {
  top: calc(100% + 0.625rem);
}
.tooltip .tooltip-drop.tooltip-bottom:before {
  bottom: 100%;
  transform: translate(-50%, 0) rotate(180deg);
}

.lumos-select{
  outline-color: #054075;
  padding: 10px 16px;
  outline: 1px solid;
  border-right: 16px solid transparent;
}

.circle {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
} 

.plan-card {
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.cost-math-grid {
  display: grid;
  grid-template-columns: 65% 5% 10% 20%;
  row-gap: 4px;
}

.inline-math-grid {
  display: grid;
  grid-template-columns: 20% 5% 20% 5% 20% 5% 25%;
  grid-template-rows: 40px 20px;
  row-gap: 4px;
}

.consequence-graph {
  width: 80%;
}

.true-cost-graph {
  width: 20%
}

.plan-card {
  width: 600px;
}

.application-title-text2 {
  font-weight: 500;
  font-size: 22px;
  color: #054075;
  line-height: 1.1;
}

.application-select-button {
  width: 350px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  display: flex;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: black;
  border: 1px solid #054075;
  border-radius: 0.5rem;
  padding: 24px 10px 24px 10px; 
}

.application-select-button:hover {
  width: 200px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  display: flex;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #054075;
  border: 1px solid #054075;
  border-radius: 0.5rem;
  padding: 24px 10px 24px 10px;  
}


.application-select-button-selected {
  width: 350px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  display: flex;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #054075;
  border: 1px solid #054075;
  border-radius: 0.5rem;
  padding: 24px 10px 24px 10px;  
}

.plan-stub-tag {
  background: #F8E589;
  border: 1px solid #000000;
  font-style: normal;
  font-size: 18px;
  line-height: 15px;
  font-weight: 600;
  margin-right: -1px;
  margin-top: 15px;
  width: 100%;
  text-align: center;
}

.plan-stub-tag-enroll {
  background: #F8E589;
  border: 1px solid #000000;
  font-style: normal;
  font-size: 18px;
  line-height: 15px;
  font-weight: 600;
  margin-right: -1px;
  margin-top: 15px;
  width: 100%;
  text-align: center;
}

.plan-stub-enroll-button {
  background: rgba(31, 116, 1, 0.8);
}

.plan-stub-view-button {
  background: rgba(8, 81, 147, 0.72);
}

.plan-stub-plan-name {
  font-size: 1vw;
}

.plan-stub-no-hover {
  box-sizing: border-box;
  border: 0.px solid #000000;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 10px;
  width: 200vh;
  height: 25%;
}

.plan-stub-no-hover-enroll {
  box-sizing: border-box;
  border: 0.px solid #000000;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 10px;
  width: 100%;
  height: 25%;
}

.chart-container {
  height:75vh;
}

.filter-menu {
  width: 20vh;
}

.no-match-text {
  width: 200vh;
}

ul.fa-ul2 > li {
  position: relative;
}

ul.fa-ul2 li:before {
  position: absolute;
  left: -13px;
}

.popup-dialog {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  opacity: 1;
  width: 170px;
  box-shadow: 0 0 2px 1px black;
}


.youtube-player {
  position: fixed;
  width: 600px;
  height: 400px;
  bottom: calc( 50% - 400px * 0.5);
  right: calc( 50% - 600px * 0.5);
  background: black;
  z-index: 999;
  box-shadow: 0 0 1px 1px black;
  border-radius: 5px;
}

.youtube-player .close {
  position: absolute;
  right: 0;
  top: -12px;
  right: -12px;
  width: 22px;
  height: 22px;
  background: black;
  color: white;
  opacity: 1;
  padding: 3px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px white;
}

@media (max-width: 767px) {
  .progress-bar-text {
    font-size: 0.70em;
    line-height: 1em;
    font-weight: 600;
  }

  .progress-bar-spacing {
    gap: 0.25rem;
    padding-left: 5px;
    padding-right: 5px;
  }

  .logo {
    width: 175px;
  }

  .contact-us {
    color: white;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem; /* 12px */
    padding-bottom: 0.5rem; /* 12px */
    border: solid 1px;
    margin-right: 1em;
    border-radius: 0.5rem;
    font-size: 0.75em;
  }

  .application-title-text {
    font-weight: 600;
    font-size: 1.75em;
    color: #054075;
    line-height: 1.15;
    margin-top: 3rem;
  }

  .application-subtitle-text {
    font-weight: 400;
    /* font-style: italic; */
    font-size: 1.1em;
    color: #00000;
    line-height: 1.25;
    margin-top: 40px;
  }

  .zipcode-input {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: 3rem;
  }

  .input-text {
    font-weight: 600;
    font-size: 1em;
  }

  .navigation-button-back {
    font-weight: 500;
    padding: 1.15rem;
    background-color: white;
    border: solid 1px black; 
    color: text;
    border-radius: 0.25rem;
    width: 7.25rem;
    font-size: 1.25rem; /* 30px */
    line-height: 1rem; /* 36px */
    margin-right: 15px;
  }

  .navigation-button-next {
    font-weight: 500;
    padding: 1.15rem;
    background-color: #054075;
    border: solid 1px #054075; 
    color: white;
    border-radius: 0.25rem;
    width: 7.25rem;
    font-size: 1.25rem; /* 30px */
    line-height: 1rem; /* 36px */
    margin-left: 15px;
  }

  .navigation-button-continue {
    font-weight: 500;
    padding: 1.15rem;
    background-color: rgb(75 85 99);
    border: solid 1px #054075; 
    color: white;
    border-radius: 0.25rem;
    width: 7.25rem;
    font-size: 1.25rem; /* 30px */
    line-height: 1rem; /* 36px */
    margin-left: 15px;
  }

  .navigation-spacing {
    display: flex;
    place-items: center;
    justify-content: center;
  }

  .applicants-component {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .applicants-age-sex {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    place-items: left;
  }

  .applicants-age-sex-input-box {
    margin-top: 8px;
  }

  .applicants-inputs-text {
    font-size: 1rem; /* 18px */
    line-height: 1.1rem; /* 28px */
    color: black;
    font-weight: 600;
  }

  .tooltip .tooltip-drop {
    position: absolute;
    max-width: 225px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    opacity: 0;
    padding: 0.75rem 0.875rem;
    background-color: #ffffff;
    font-size: 0.80rem;
    text-align: left;
    z-index: 10;
    transition: all 0.15s ease-in-out;
    border-radius: 0.188rem;
    color: #000;
    border-color: #000;
    border-width: 1px;
    pointer-events: none;
  }

  .household-component {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .household-subtitle-spacing {
    margin-left: 10px; 
    margin-right: 10px; 
  }

  .household-inputs-spacing{
    padding-left: 0.75rem; /* 64px */
    padding-right: 0.75rem; /* 64px */
  }

  .household-inputs-text {
    font-size: 1rem; /* 18px */
    line-height: 1.3rem; /* 28px */
    color: black;
    font-weight: 600;
  }

  .subsidy-output-text {
    font-size: 1rem; /* 18px */
    line-height: 1.5rem; /* 28px */
    text-align: center;
    font-weight: 600;
  }

  .subsidy-amount-output-text {
    color: #0D930A; /* 18px */
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
    text-align: center;
    font-weight: 600;
  }

  .special-enrollment-component {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .providers-search-bar {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .drugs-search-bar {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .drug-name-text-size {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    font-weight: 600;
  }

  .drugs-provider-result-spacing {
    padding-left: 0.5rem; /* 20px */
    padding-right: 0.5rem; /* 20px */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .drugs-provider-result-add-button {
    padding-left: 0.5rem; /* 20px */
    padding-right: 0.5rem; /* 20px */
    padding-top: 0.5rem; /* 12px */
    padding-bottom: 0.5rem; /* 12px */
    margin-top: 10px;
    max-width: 60px;
  }

  .health-status-buttons {
    padding-top: 14px;
    padding-bottom: 14px; 
    padding-left: 28px;
    padding-right: 28px;
  }

  .health-status-yes {
    margin-right: 20px;
  }

  .health-status-no {
    margin-left: 20px;
  }

  .health-status-component {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .health-status-arrange {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
  }

  .health-status-selector {
    margin-top: 18px;
    width: 11rem;
  }

  .preferences-component {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .preferences-inputs-text {
    font-size: 1rem; /* 18px */
    line-height: 1.3rem; /* 28px */
    color: black;
    font-weight: 600;
  }

  .preferences-selector {
    width: 83.333333%;
    padding-left: 1rem;
    margin-top: 0.5rem;
  }

  .skip_to_recommend {
    display: None;
  }


  .save-progress-button-next {
    font-weight: 500;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    /* padding-left: 1rem;
    padding-right: 1rem; */
    /* padding: 1.15rem; */
    background-color: #1F7401;
    border: solid 1px #1F7401; 
    color: white;
    border-radius: 0.25rem;
    width: 100%;
    font-size: 1rem; /* 20px */
    line-height: 1.5rem; /* 28px */
  }

  .save-progress-email-input {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .save-progress-button-spacing {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .results-web {
    display: None;
  }

  .results-mobile {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.5em;
    font-weight: 400;
    text-align: center;
  }

  .household-info-icon {
    width: 20px;
  }

  .submit-web {
    display: None;
  }

}

@media (min-width: 768px) {
  .progress-bar-text {
    font-size: 1.15em;
    line-height: 1.5rem;
    font-weight: bold;
  }

  .progress-bar-spacing {
    gap: 0.75rem;
    /* padding-left: 5px;
    padding-right: 5px; */
  }

  .contact-us {
    color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem; /* 12px */
    padding-bottom: 0.75rem; /* 12px */
    border: solid 1px;
    margin-right: 1em;
    border-radius: 0.5rem;
  }

  .application-title-text {
    font-weight: 600;
    font-size: 32px;
    color: #054075;
    line-height: 1.3;
    margin-top: 2.5rem;
  }

  .application-subtitle-text {
    font-weight: 400;
    /* font-style: italic; */
    font-size: 18px;
    color: #00000;
    line-height: 1.3;
    margin-top: 20px;
  }

  .zipcode-input {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 2.5rem;
  }

  .input-text {
    font-weight: 600;
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
  }

  .navigation-button-back {
    font-weight: 500;
    padding: 1rem;
    background-color: white;
    border: solid 1px black; 
    color: text;
    border-radius: 0.25rem;
    width: 16rem;
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
    margin-right: 30px;
  }

  .navigation-button-next {
    font-weight: 500;
    padding: 1rem;
    background-color: #054075;
    border: solid 1px #054075; 
    color: white;
    border-radius: 0.25rem;
    width: 16rem;
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
    margin-left: 30px;
  }

  .navigation-spacing {
    display: flex;
    place-items: center;
    justify-content: center;
  }

  .applicants-component {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .applicants-age-sex {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    place-items: center;
    justify-content: space-between;
  }

  .applicants-inputs-text {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
    color: black;
    font-weight: 600;
  }

  .tooltip .tooltip-drop {
    position: absolute;
    max-width: 330px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    opacity: 0;
    padding: 0.75rem 0.875rem;
    background-color: #ffffff;
    font-size: 0.875rem;
    text-align: left;
    z-index: 10;
    transition: all 0.15s ease-in-out;
    border-radius: 0.188rem;
    color: #000;
    border-color: #000;
    border-width: 1px;
    pointer-events: none;
  }

  .household-component {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .household-inputs-spacing{
    padding-left: 4rem; /* 64px */
    padding-right: 4rem; /* 64px */
  }

  .household-inputs-text {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
    color: black;
    font-weight: 600;
  }

  .subsidy-output-text {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
    text-align: center;
    font-weight: 600;
  }

  .subsidy-amount-output-text {
    color: #0D930A; /* 18px */
    font-size: 3rem; /* 48px */
    line-height: 1;
    text-align: center;
    font-weight: 600;
  }

  .special-enrollment-component {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .providers-search-bar {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .drugs-search-bar {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .drug-name-text-size {
    font-size: 1.125rem; /* 12px */
    line-height: 1.75rem; /* 16px */
    font-weight: 600;
  }

  .drugs-provider-result-spacing {
    padding-left: 1.25rem; /* 20px */
    padding-right: 1.25rem; /* 20px */
    display: flex;
    flex-direction: row;
    place-items: center;
    align-items: between;
    justify-content: space-between;
  }

  .drugs-provider-result-add-button {
    padding-left: 1.25rem; /* 20px */
    padding-right: 1.25rem; /* 20px */
    padding-top: 0.75rem; /* 12px */
    padding-bottom: 0.75rem; /* 12px */
  }

  .health-status-buttons {
    padding-top: 1rem;
    padding-bottom: 1rem; 
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .health-status-yes {
    margin-right: 2.5rem;
  }

  .health-status-no {
    margin-left: 2.5rem;
  }

  .health-status-arrange {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .health-status-selector {
    width: 18rem;
    margin-left: 4rem;
  }

  .preferences-selector {
    width: 100%;
    padding-left: 1rem;
    margin-top: 0.5rem;
  }

  .save-progress-button-next {
    font-weight: 500;
    padding: 1rem;
    background-color: #1F7401;
    border: solid 1px #1F7401; 
    color: white;
    border-radius: 0.25rem;
    width: 100%;
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
  }

  .skip_to_recommend {
    display: flex;
    justify-content: center;
  }

  .save-progress-email-input {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .save-progress-button-spacing {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .results-mobile {
    display: None;
  }

  .submit-mobile {
    display: None;
  }

  .fixed-filter {
    position:fixed;
    z-index:100;
}

  .plan-param-img {
    max-width: 100px;
  }

}

}